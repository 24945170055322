import React from "react";
import "./App.css";
import Navbar from "./componenets/navbar/Navbar";
import { Box, Grid, Typography } from "@mui/material";
import sign from "./images/coming-soon-sign.png";

function App() {
  return (
    <>
      <div className="App">
        <Navbar />
        <header>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            minHeight="50vh"
            width="100%"
            bgcolor="transparent"
          >
            <Grid item>
              <Box
                component="img"
                alt="Wnet logo"
                src={sign}
                sx={{
                  maxHeight: 150,
                  maxWidth: "75%",
                  margin: 5,
                  paddingTop: 15,
                }}
              />
            </Grid>
            <Grid item width="50%" minWidth={300}>
              <Typography
                variant="h5"
                position="relative"
                fontFamily="Lato"
                fontWeight="400"
              >
                Szukasz sposobu na rozwój zawodowy i osobisty?
                <br />
                Wnet to wydarzenia rozwojowe w jednym miejscu, o których
                istnieniu dowiesz się na czas!
                <br />
                Na naszej stronie już niedługo znajdziesz programy szkoleniowe,
                warsztaty, konferencje, meetupy i programy mentoringowe, które
                pozwolą Ci zdobyć nowe umiejętności, zmienić branżę, awansować,
                czy poznać inspirujące osoby.
                <br />
                <br />
                Uzupełnij <a href="https://tiny.pl/dncfc">formularz</a> i odkryj
                nowe możliwości już wkrótce!
              </Typography>
            </Grid>
          </Grid>
        </header>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="50vh"
          bgcolor="transparent"
        ></Box>
      </div>
    </>
  );
}

export default App;
