import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as InstagramSVG } from "../../images/instagram.svg";
import { ReactComponent as LinkedinSVG } from "../../images/linkedin.svg";
import { ReactComponent as MailSVG } from "../../images/mail.svg";
import logo from "../../images/logo-full.png";
import "./Navbar.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FFFFFF",
      light: "#FFFFFF",
      dark: "#FFFFFF",
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
});

export default function Navbar() {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar>
          <Toolbar color="primary" sx={{ boxShadow: 1 }}>
            <Box
              component="img"
              alt="Wnet logo"
              src={logo}
              sx={{ maxHeight: 70, margin: 1 }}
            />
            <Box sx={{ flexGrow: 1 }} />
            {/* <Button size="large" color="inherit">
              <Typography variant="h6" component="div" margin="0 20px">
                O nas
              </Typography>
            </Button> */}
            <IconButton href="https://www.instagram.com/wnet.events">
              <InstagramSVG />
            </IconButton>
            <IconButton href="https://www.linkedin.com/company/wnet-events/about/">
              <LinkedinSVG />
            </IconButton>
            <IconButton href="mailto:wnet.events@gmail.com">
              <MailSVG />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Box>
    </ThemeProvider>
  );
}
